.ui.main.text.container.aboutbody {
    max-width: 100%; /* To ensure it takes the full width if needed */
    padding: 2rem; /* Adjust padding as necessary */
    box-sizing: border-box; /* To include padding in the width calculation */
}

.ui.main.text.container.aboutbody section {
    margin-bottom: 2rem; /* Adds space between sections */
}

/* Aligns all h2 and h3 elements to the left */
.ui.main.text.container.aboutbody h2,
.ui.main.text.container.aboutbody h3 {
    text-align: left;
}

/* Optional: If you want the text to be more centralized and not too wide */
@media (min-width: 768px) {
    .ui.main.text.container.aboutbody {
        max-width: 750px; /* Adjust to desired max-width */
        margin-left: auto; /* Centers the container */
        margin-right: auto; /* Centers the container */
    }
}

/* Additional styling to make the page more appealing */
/* Feel free to adjust colors, fonts, and other properties as needed */
.ui.main.text.container.aboutbody {
    font-family: 'Arial', sans-serif; /* Change font family as needed */
    color: #333; /* This is a dark gray color for the text */
}

.ui.main.text.container.aboutbody a {
    color: #0066cc; /* This is a sample link color */
}

.ui.main.text.container.aboutbody a:hover {
    color: #0055a5; /* Darker shade for hover state */
}
